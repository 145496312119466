<script lang="ts">
	import Protect from '../lib/Protect.svelte';
	import { UserRole } from '../types/Enums';
	import NavHeader from './NavHeader.svelte';
	import NavItem from './NavItem.svelte';
</script>

<Protect roles={[
	UserRole.ADMIN,
]}>
	<NavHeader text="Administrace - Provoz" />
	<NavItem icon="fa-solid fa-server" text="Správa serverů" href="/admin/servers" />
	<NavItem icon="fa-solid fa-users" text="Správa uživatelů" href="/admin/users" />
	<NavItem icon="fa-solid fa-shop" text="Správa Marketplace" href="/admin/marketplace" />
	<NavItem icon="fa-solid fa-money-bill" text="Přehled plateb" href="/admin/payments" />
	<NavItem icon="fa-solid fa-tools" text="Nástroje" href="/admin/utils" />
	<NavItem icon="fa fa-clock-rotate-left" text="Log" href="/admin/log" />

	<NavHeader text="Administrace - Nastavení" />
	<NavItem icon="fa-solid fa-box" text="Produkty" href="/admin/products" />
	<NavItem icon="fa-solid fa-table-list" text="Kategorie" href="/admin/categories" />
	<NavItem icon="fa-brands fa-docker" text="Docker obrazy" href="/admin/eggs" />
	<NavItem icon="fa-solid fa-inbox" text="Infoboard" href="/admin/infoboard" />
	
</Protect>
