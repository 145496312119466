<script lang="ts">
  import { Link } from "svelte-routing";
  import { localDeviceSettings } from "../lib/local-device-settings";
  import { ActiveDomain } from "../store";
  import NavHeader from "./NavHeader.svelte";
  import NavItem from "./NavItem.svelte";

	let hidden = true;

	const updateActiveDomain = () => {
		if ($ActiveDomain && !location.pathname.includes('/domain/') && !$localDeviceSettings.pinServer) {
			hidden = true;
			setTimeout(() => {
				ActiveDomain.set(undefined);
			}, 250);
		}
		return {
			style: "display:none;"
		};
	}

	async function toggleServerPin() {
		localDeviceSettings.update(settings => {
			settings.pinServer = !settings.pinServer;
			if (!settings.pinServer) {
				updateActiveDomain();
			}
			return settings;
		});
	}

	$: {
		if ($ActiveDomain) {
			hidden = false;
		}
	}
</script>

<Link to='/domain' getProps={updateActiveDomain}>Location Detection</Link>

{#if $ActiveDomain}
<div class="menu-show-animation" class:hiding={hidden} >
	<NavHeader text={`Doména ${($ActiveDomain?.domain ?? '')}`}>
		<button class="pin-icon" class:active={$localDeviceSettings.pinServer} on:click={toggleServerPin} title="Připnout aktivní server">
			<i class="fa-solid fa-thumbtack"></i>
		</button>
	</NavHeader>

	<!-- Stav -->
	<NavItem icon="fa-solid fa-chart-simple" text="Stav" href="/domain/{$ActiveDomain.id}" exact />
	<!-- <NavItem icon="fa-solid fa-signs-post" text="DNS" href="/domain/{$ActiveDomain.id}/dns" /> -->
	<NavItem icon="fa-solid fa-folder-tree" text="Proxy" href="/domain/{$ActiveDomain.id}/proxy" />
	<!-- <NavItem icon="fa fa-users" text="Správci" href="/domain/{$ActiveDomain.id}/users" /> -->
</div>
{/if}

<style lang="scss">
	.pin-icon {
		float: right;
		transform: translateX(100%) rotate(45deg);
		opacity: .3;
		cursor: pointer;
		transition: all .1s ease-in-out;
		background-color: transparent;
		border: none;
		padding: 0;
	}
	.pin-icon:hover {
		opacity: .8;
	}
	.pin-icon.active {
		opacity: 1;
		transform: translateX(100%) rotate(0deg);
	}

	.menu-show-animation {
		animation: menu-show .3s ease-in-out;
	}
	.menu-show-animation.hiding {
		animation: menu-hide .3s ease-in-out;
	}

	@keyframes menu-show {
		0% {
			opacity: 0;
			transform: translateX(-100%);
			max-height: 0;
		}
		100% {
			opacity: 1;
			transform: translateX(0%);
			max-height: 100vh;
		}
	}
	@keyframes menu-hide {
		0% {
			opacity: 1;
			transform: translateX(0%);
			max-height: 100vh;
		}
		100% {
			opacity: 0;
			transform: translateX(-100%);
			max-height: 0;
		}
	}
</style>
